<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="320"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('app.general.confirm_delete') }}
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          {{ $t('app.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="red dark"
          @click="deleteItem"
        >
          {{ $t('app.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'JornadasActionDelete',
    data () {
      return {
        item: false,
        dialog: false,
      }
    },
    mounted () {
      EventBus.$on('jornadas-action-delete', (item) => {
        this.item = item
        this.dialog = true
      })
    },
    methods: {
      async deleteItem () {
        this.dialog = false
        this.toggleLoader()

        await this.$http.delete(route('v1/action/' + this.item.id))
          .then(response => {
            if (response.body.code === 200) {
              this.processSuccess(response)
              EventBus.$emit('reload-journey')
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })

        this.toggleLoader()

        EventBus.$emit('reload-items')
      },
    },
  }
</script>

<style scoped>

</style>
